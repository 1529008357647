//
// This HOC wraps actual app components and provides app-wide features as basic snack and dialog message
// displays. The logic for checking for app updates and installing them is found here.
//

import React, { Component, ReactElement } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import {
    Grid,
    Typography,
    CircularProgress,
    Snackbar,
    Button,
} from "@material-ui/core"

import { isEmptyString } from "./shared/utils"
import { ProcessingSnackbar } from "./shared/components/ProcessingSnackbar"
import { LoadingDialog } from "./shared/components/LoadingDialog"
import { StatusSnackbar, StatusSnackbarTypes } from "./shared/components/StatusSnackbar"
import { ServiceWorkerState, GQLClientState } from "./redux/base"
import { clearCredentials, clearGQLClient } from "./redux/actions"
import { GQL_ADMIN_LOGOUT } from "./fetchyql/queries"
import { SuccessfulRequestData } from "./fetchyql/datatypes"
import { executeGraphQLQueryAsync, FetchyQLErrorType } from "./fetchyql/base"


interface AppFrameContainerProps {
    component: ReactElement,

    // redux state
    serviceworker: ServiceWorkerState,
    gqlclient: GQLClientState

    // redux actions
    clearCredentials: () => void,
    clearGQLClient: () => void
}

interface AppFrameContainerState {
    component: ReactElement,
    statusSnackMessage: {
        variant: StatusSnackbarTypes,
        value: string
    } | null,
    statusSnackbarId: string,
    fgDialogMessage: string,
    processingMessage: string,

    updating: boolean,
    redirectParams: { pathname: string } | null
}

class AppFrameContainer extends Component<AppFrameContainerProps, AppFrameContainerState> {

    constructor(props: AppFrameContainerProps) {
        super(props)
        this.state = {
            component: <React.Fragment/>,
            statusSnackMessage: null,
            statusSnackbarId: uuidv4(),
            fgDialogMessage: "",
            processingMessage: "",
            updating: false,
            redirectParams: null
        }
    }

    componentDidMount = () => {
        const componentProps = {
            onLogout: this._onLogout,
            setSnackMessage: this.setStatusMessage,
            setForegroundDialog: this.setForegroundDialog,
            setProcessingMessage: this.setProcessingMessage,
            clearStatusMessage: this.clearStatusMessage,
            clearMessages: this.clearMessages
        }
        this.setState({
            component: React.cloneElement(this.props.component, componentProps)
        })
    }

    componentDidUpdate = (prevProps: AppFrameContainerProps, prevState: AppFrameContainerState) => {
        if (prevProps.component !== this.props.component) {
            const componentProps = {
                onLogout: this._onLogout,
                setSnackMessage: this.setStatusMessage,
                setForegroundDialog: this.setForegroundDialog,
                setProcessingMessage: this.setProcessingMessage,
                clearStatusMessage: this.clearStatusMessage,
                clearMessages: this.clearMessages
            }
            this.setState({
                component: React.cloneElement(this.props.component, componentProps)
            })
        }

        if (prevState.statusSnackMessage) {
            if (prevState.statusSnackMessage.value !== this.state.statusSnackMessage?.value ||
                prevState.statusSnackMessage.variant !== this.state.statusSnackMessage?.variant) {
                this.setState({ statusSnackbarId: uuidv4() })
            }
        }
    }

    setStatusMessage = (variant: StatusSnackbarTypes, value: string) => this.setState({statusSnackMessage: {variant, value}})
    setForegroundDialog = (message: string) => this.setState({fgDialogMessage: message})
    setProcessingMessage = (message: string) => this.setState({processingMessage: message})
    clearStatusMessage = () => this.setState({statusSnackMessage: null})
    clearMessages = () => this.setState({statusSnackMessage: null, fgDialogMessage: "", processingMessage: ""})


    _onLogout = async () => {
        this.setForegroundDialog("Signing out...")

        try {
            const payload: SuccessfulRequestData = await this._logoutAsync()
            this.clearMessages()
            this.setStatusMessage(StatusSnackbarTypes.SUCCESS, payload.message)
            this.setState({
                redirectParams: { pathname: "/"}
            })
        }
        catch (error) {
            if(error.name === FetchyQLErrorType.REQUEST_ERROR) {
                console.error(error.message)
            }
            else if(error.name === FetchyQLErrorType.NETWORK_ERROR) {
                console.error(error.errors[0])
            }
        }
    }

    _onClickUpdateApp = () => {
        // a fake delay to make it seem like work is being done, haha. this is actually
        // instant
        this.setState({
            updating: true
        }, () => {
            setTimeout(() => {
                const waitingRegistration = this.props.serviceworker.registration.waiting
                // if there's a new service worker register waiting to be installed.. then install it
                if (waitingRegistration) {
                    waitingRegistration.postMessage({ type: 'SKIP_WAITING' });
                    waitingRegistration.addEventListener('statechange', (e: any) => {
                        if (e.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                }
            }, 1500)
        })
    }

    _logoutAsync = async (): Promise<SuccessfulRequestData> => {
        const payload: SuccessfulRequestData = await executeGraphQLQueryAsync<SuccessfulRequestData>(
            this.props.gqlclient.client,
            GQL_ADMIN_LOGOUT
        )
        return payload
    }


    _renderStatusSnackbar = (): React.ReactNode => {
        if (this.state.statusSnackMessage) {
            const variant = this.state.statusSnackMessage.variant as keyof typeof StatusSnackbarTypes || StatusSnackbarTypes.INFO
            return (
                <StatusSnackbar
                    open={!isEmptyString(this.state.statusSnackMessage.value)}
                    message={{
                        variant: StatusSnackbarTypes[variant],
                        value: this.state.statusSnackMessage.value || "Loading..."
                    }}
                    onClose={this.clearStatusMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    key={this.state.statusSnackbarId} />
            )
        }
        else {
            return <React.Fragment />
        }
    }


    render = () => {
        if (this.state.redirectParams) {
            return <Redirect to={this.state.redirectParams} />
        }
        else if (this.state.updating) {
            return (
                <Grid container justifyContent="center" alignItems="center" direction="row" style={{ height: "100vh" }}>
                    <Grid item>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item><CircularProgress color="primary" /></Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item><Typography variant="body1">Getting the newest features</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }

        return (
            <React.Fragment>
                {this.state.component}
                <LoadingDialog show={!isEmptyString(this.state.fgDialogMessage)} message={this.state.fgDialogMessage} />
                {this._renderStatusSnackbar()}
                <Snackbar open={this.props.serviceworker.updated}
                    message="An app update is available!"
                    action={<Button color="primary" onClick={this._onClickUpdateApp}>Update Now</Button>} />
                <ProcessingSnackbar
                    label="Checking for updates..."
                    open={this.props.serviceworker.checking} />
                <ProcessingSnackbar
                    label={this.state.processingMessage || "Processing..."}
                    open={!isEmptyString(this.state.processingMessage)} />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { serviceworker, gqlclient } = state
    return { serviceworker, gqlclient }
}

const mapActionCreatorsToProps = {
    clearCredentials,
    clearGQLClient
}


export default connect(mapStateToProps, mapActionCreatorsToProps)(AppFrameContainer)